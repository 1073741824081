<template>
  <div>
    <page-header-admin class="mb-2" :title="$t('Mentees')">
      <template #actions>
        <b-button
          variant="outline-primary"
          class="btn-icon mr-2"
          @click="downloadMentees"
        >
          <feather-icon icon="DownloadIcon" />
          {{ $t('Download') }}
        </b-button>
        <!-- <b-button
          variant="primary"
          class="btn-icon"
          v-b-toggle="'mentor-filters-sidebar'"
        >
          <feather-icon icon="SlidersIcon" />
          {{ $t('Filters') }}
        </b-button>  -->
      </template>
    </page-header-admin>
    <b-card no-body>
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('Records') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                debounce="100"
                type="search"
                class="d-inline-block mr-1"
                :placeholder="$t('Search...')"
              />

            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        :items="mentees"
        responsive
        :fields="fields"
        :filter="searchQuery"
        :filter-function="filterFunction"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="''"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
        no-provider-sorting
        no-provider-paging
        no-provider-filtering
        no-sort-reset
        :per-page="perPage"
        :current-page="currentPage"
        @filtered="onFiltered"
      >
        <template #head()="{label}">
          {{ label | upper }}
        </template>

        <!-- Column: fullname -->
        <!-- eslint-disable-next-line -->
        <template #cell(user.surname)="data">
          <b-link :to="{ name: 'admin-mentee-page', params: { id: data.item.id } }">
            {{ data.item.user.name }} {{ data.item.user.surname }}
          </b-link>
        </template>

        <!-- Column: created_at -->
        <template #cell(created_at)="{item}">
          {{ item.created_at | formatGreekDate({ month: '2-digit', year: 'numeric', day: '2-digit'  }) }}
        </template>

        <!-- Column: last_scholarship -->
        <template #cell(last_scholarship)="data">
          <template v-if="data.value.id">
            <feather-icon icon="CheckIcon" class="text-primary" size="16" /> ({{ data.value.name }})
          </template>
          <feather-icon v-else icon="XIcon" size="16" />
        </template>

        <!-- Column: is_on_mentorship -->
        <template #cell(is_on_mentorship)="data">
          <feather-icon v-if="data.value" icon="CheckIcon" class="text-primary" size="16" />
          <feather-icon v-else icon="XIcon" size="16" />
        </template>

        <!-- Column: admin_comments -->
        <template #cell(admin_comments)="data">
          <feather-icon v-if="data.value" icon="CheckIcon" class="text-primary" size="16" />
          <feather-icon v-else icon="XIcon" size="16" />
        </template>

        <!-- Column: actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'admin-mentee-page', params: { id: data.item.id } }">
              {{ $t('Edit profile') }}
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-card>
    <div>
      <b-row>
        <b-col
          class="d-flex justify-content-end"
        >
          <b-pagination
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            :per-page="perPage"
            v-model="currentPage"
            first-number
            last-number
            :total-rows="totalRows"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BLink,
  BPagination,
  BRow,
  BTable,
  VBToggle
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import PageHeaderAdmin from '@mentoring-platform/views/components/blocks/PageHeaderAdmin.vue'
import { useMenteeRepository } from '@mentoring-platform/composables'
import { upper, formatGreekDate } from '@mentoring-platform/utils/filters'

export default {
  components: {
    BButton,
    BCard,
    BCol,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BLink,
    BPagination,
    BRow,
    BTable,
    VBToggle,
    PageHeaderAdmin,
    vSelect
  },
  directives: {
    'b-toggle': VBToggle,
  },
  filters: {
    formatGreekDate,
    upper
  },
  data() {
    return {
      perPage: 50,
      perPageOptions: [10, 25, 50, 100],
      currentPage: 1,
      totalRows: 0,
      searchQuery: null,
      sortBy: 'id',
      isSortDirDesc: true,
    }
  },
  computed: {
    fields() {
      return [{
        key: 'id',
        label: 'ID',
        sortable: true
      },
      {
        key: 'user.surname',
        label: this.$t('Full name'),
        sortable: true,
      },
      {
        key: 'created_at',
        label: this.$t('Registration date'),
        sortable: true
      },
      {
        key: 'last_scholarship',
        label: this.$t('Scholarship'),
        sortable: true
      },
      {
        key: 'is_on_mentorship',
        label: this.$t('Mentee is on mentorship'),
        sortable: true
      },
      {
        key: 'admin_comments',
        label: this.$t('Comments'),
        sortable: true,
      },
      {
        key: 'actions',
        label: this.$t('Actions')
      }]
    }
  },
  watch: {
    mentees(newValue) {
      this.totalRows = newValue.length
    }
  },
  methods: {
    filterFunction(item, filterProp) {
      const regex = new RegExp(filterProp.trim(), 'gi')
      return `${item.user.name} ${item.user.surname}`.match(regex)
        || item.user.email.match(regex)
        || (item.last_scholarship && item.last_scholarship.name.match(regex))
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    downloadMentees() {
      this.$store.dispatch('mentee/downloadExport')
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'mentees.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch(error => {

        })
    }
  },
  setup() {
    const { mentees, getMentees, refreshMentees } = useMenteeRepository()
    refreshMentees()

    return {
      mentees,
      getMentees,
      refreshMentees
    }
  }
}
</script>

<style lang="scss" scoped>

</style>